import React, { ChangeEvent, useEffect, useState } from 'react';

import SearchIcon from '../../assets/svg/SearchIcon';
import FormGroupWrapper from '../form/FormGroupWrapper';

import { TOASTER_STATUS } from '../../constant';
import { useLanguage } from '../../context/LanguageContext';
import { showToastMessage, validateHtmlTag } from '../../utils';
import './index.scss';

interface IProps {
  searchValue: string;
  handleSearch: (value: string) => void; // eslint-disable-line
}

const SearchInputWrapper: React.FC<IProps> = ({ searchValue, handleSearch }) => {
  const [value, setValue] = useState<string>('');

  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    index: {
      search: '',
      htmlTagsError: '',
      maxCharactersError: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  useEffect(() => {
    setValue(searchValue);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget?.value;
    if (currentValue.trim()?.length <= 255) {
      setValue(currentValue);
      if (validateHtmlTag(currentValue)) {
        showToastMessage(TOASTER_STATUS.ERROR, content.index.htmlTagsError);
      } else {
        handleSearch(currentValue);
      }
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, content.index.maxCharactersError);
    }
  };

  useEffect(() => {
    if (!searchValue) {
      setValue('');
      handleSearch('');
    }
  }, [searchValue]);

  return (
    <div className='search-container'>
      <div className='d-md-flex d-block search-bar'>
        <FormGroupWrapper
          hasLabel={false}
          placeholder={content.index.search || 'Search...'}
          type='text'
          autoComplete='off'
          name='search'
          onChange={handleChange}
          className='from-group-wrapper'
          value={value}
          error={undefined}
          elementRender={<SearchIcon />}
        />
      </div>
    </div>
  );
};

export default SearchInputWrapper;
