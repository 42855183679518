import * as Yup from 'yup';
import { convertBytesIntoMB, getAudioDuration, getMinutesFromSeconds } from '.';

export const getRequiredText = (field: string) => `${field} is required`;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
const categoryRegex = /^[A-Za-z-]+$/;

export const checkoutFormSchema = Yup.object().shape({
  name: Yup.string().required(getRequiredText('Full Name')),
});

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(25, 'Maximum 25 characters allowed')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .max(25, 'Maximum 25 characters allowed')
    .required('Last name is required'),
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(50, 'Maximum 50 characters allowed')
    .matches(emailRegex, 'Email address must be a valid email'),
  country: Yup.string().notOneOf(['Select your Country'], 'Please select your country'),
  dateOfBirth: Yup.string()
    .max(10, 'Please select right year')
    .required('Date of birth is required')
    .test('is-valid-date', 'Invalid date. Please select a valid date.', (value) => {
      // Check if the date is a valid date and not in the future
      const parsedDate = new Date(value);
      const currentDate = new Date();

      return !Number.isNaN(parsedDate.getTime()) && parsedDate <= currentDate;
    }),
  gender: Yup.string().required('Please select a gender'),
  bio: Yup.string()
    .max(255, 'Maximum 255 characters allowed')
    .test(
      'html-tags',
      'For security reasons, HTML tags are not allowed',
      (value) => !htmlTagRegex.test(value || '') // Return true if the value does NOT contain HTML tags
    ),
});

export const editAdminProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(50, 'Maximum 50 characters allowed')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .max(50, 'Maximum 50 characters allowed')
    .required('Last name is required'),
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(50, 'Maximum 50 characters allowed')
    .matches(emailRegex, 'Email address must be a valid email'),
  bio: Yup.string().test(
    'html-tags',
    'For security reasons, HTML tags are not allowed',
    (value) => !htmlTagRegex.test(value || '') // Return true if the value does NOT contain HTML tags
  ),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Please enter your old password'),
  newPassword: Yup.string()
    .required('Please enter your new password')
    .min(8, 'Password must be at least 8 characters')
    .max(20, 'Password must be at most 20 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .matches(/^[^\s]+$/, 'Space are not allowed in the password')
    .notOneOf([Yup.ref('oldPassword'), ''], 'New Passwords and  Old Password cannot be same'),
  confirmPassword: Yup.string()
    .required('Confirm password must')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});

export const episodeSchema = Yup.object().shape({
  thumbnail: Yup.string().required(getRequiredText('Thumbnail')),
  cover: Yup.string().required(getRequiredText('Cover')),
  episodeNo: Yup.string()
    .required(getRequiredText('Episode No'))
    .test(
      'valid-positive-number',
      'Please enter a valid positive integer for the episode number',
      (value) => Number(value) > 0
    ),
  episodeTitle: Yup.string()
    .trim()
    .required(getRequiredText('Episode title'))
    .min(4, 'Please enter a title between 4 to 255 characters')
    .max(255, 'Please enter a title between 4 to 255 characters'),
  description: Yup.string()
    .trim()
    .required(getRequiredText('Description'))
    .min(10, 'Description should be 10 to 500 characters')
    .max(500, 'Description should be 10 to 500 characters'),
  episodeCountry: Yup.string().required('Please select a valid country for the episode'),
});

export const featuredPodcastSchema = Yup.object().shape({
  startDate: Yup.string().required(getRequiredText('Start Date')),
  endDate: Yup.string().required(getRequiredText('End Date')),
});

export const bankDetailsSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required(getRequiredText('Account number'))
    .min(12, 'Account number must be at least 12 characters')
    .max(16, 'Please enter a Account number between 12 to 16 characters'),
  ifscCode: Yup.string()
    .required(getRequiredText('IFSC code'))
    .test('no-spaces', 'Spaces are not allowed', (value) => !/\s/.test(value))
    .min(10, 'IFSC code must be at least 10 characters')
    .max(16, 'Please enter a IFSC code between 10 to 16 characters'),
  bankName: Yup.string()
    .required(getRequiredText('Bank name'))
    .matches(/^[^\d]+$/, 'Bank name should not contain numbers')
    .test(
      'no-leading-trailing-spaces',
      'Leading and trailing spaces are not allowed',
      (value) => !/^\s|\s$/.test(value)
    )
    .max(50, 'Maximum 50 characters allowed'),
  holderName: Yup.string()
    .required(getRequiredText('Account holder name'))
    .matches(/^[^\d]+$/, 'Account holder name should not contain numbers')
    .test(
      'no-leading-trailing-spaces',
      'Leading and trailing spaces are not allowed',
      (value) => !/^\s|\s$/.test(value)
    )
    .max(50, 'Maximum 50 characters allowed'),
});

export const stripeAccountSchema = Yup.object().shape({
  country: Yup.string()
    .required(getRequiredText('Country'))
    .notOneOf(['Select your Country'], 'Please select your country'),
});

export const submitPayoutProofSchema = Yup.object().shape({
  image: Yup.string().required(getRequiredText('Proof')),
  transactionDate: Yup.string().required(getRequiredText('Date')),
  comment: Yup.string()
    .required(getRequiredText('Date'))
    .max(255, 'Maximum 255 characters allowed'),
});

export const validateAudioWithDuration = async (
  track: File,
  maxSize: number,
  maxDuration?: number | undefined,
  minDuration?: number | undefined
) => {
  const trackSize = convertBytesIntoMB(track.size);
  const duration: number = await new Promise((resolve) => {
    getAudioDuration(track, (_duration: number) => {
      resolve(_duration);
    });
  });

  if (duration && minDuration) {
    if (duration < minDuration) {
      if (minDuration < 60) {
        return `Audio duration should not be less than ${minDuration} sec`;
      }
      return `Audio duration should not be less than ${getMinutesFromSeconds(minDuration)
        .replace('min', 'Minutes')
        .replace('sec', 'Seconds')}`;
    }
    if (trackSize > maxSize) {
      return maxSize ? `File size should not be more than ${maxSize} MB` : 'File size too small';
    }
  }
  if (duration && maxDuration) {
    if (Math.round(duration) > maxDuration) {
      if (maxDuration < 60) {
        return `Audio duration should not be more than ${maxDuration} sec`;
      }
      return `Audio duration should not be more than ${getMinutesFromSeconds(maxDuration)
        .replace('min', 'Minutes')
        .replace('sec', 'Seconds')}`;
    }
    if (trackSize > maxSize) {
      return maxSize ? `File size should not be more than ${maxSize} MB` : 'File size too large';
    }
    return null;
  }

  return '';
};

export const validateAudio = (track: File, maxSize: number) => {
  const trackSize = convertBytesIntoMB(track.size);

  if (trackSize > maxSize) {
    return maxSize ? `File size should not be more than ${maxSize} MB` : 'File size too large';
  }
  return null;
};

export const blockReasonSchema = Yup.object().shape({
  reason: Yup.string()
    .required(getRequiredText('Reason'))
    .max(255, 'Maximum 255 characters allowed'),
});

export const updatePlanAmountSchema = Yup.object().shape({
  amount: Yup.string()
    .required(getRequiredText('Amount'))
    .matches(/^[1-9]\d{0,3}$/, 'Amount must be a positive number with up to 4 digits'),
});

export const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required(getRequiredText('Category Name'))
    .matches(categoryRegex, 'Only hyphens (-) and alphabets are allowed in category')
    .min(3, 'Category name should be between 3 to 30 characters')
    .max(30, 'Maximum 30 characters allowed'),
});

export const inviteAdminSchema = Yup.object().shape({
  firstName: Yup.string().trim().max(50, 'Maximum 50 characters allowed'),
  lastName: Yup.string().trim().max(50, 'Maximum 50 characters allowed'),
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(50, 'Maximum 50 characters allowed')
    .matches(emailRegex, 'Email address must be a valid email'),
});

export const addUserSchema = Yup.object().shape({
  email: Yup.string()
    .required(getRequiredText('Email'))
    .max(50, 'Maximum 50 characters allowed')
    .matches(emailRegex, 'Email address must be a valid email'),
});

export const addPromoCodeSchema = Yup.object().shape({
  couponName: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Only characters or numbers are allowed')
    .required(getRequiredText('Promo code name'))
    .min(4, 'Minimum 4 characters required')
    .max(50, 'Maximum 50 characters allowed'),
  coupounUsageType: Yup.string().required(getRequiredText('Uses')),
  maxRedemptions: Yup.string()
    .nullable()
    .matches(/^[0-9]*$/, 'Uses limit must not be in decimal')
    .test('coupounUsageType', 'Uses limit is required', (value, context) => {
      const { coupounUsageType } = context.parent;
      if (coupounUsageType === 'Limited') {
        return Number(value) > 0;
      }
      return true;
    }),
  couponOffType: Yup.string(),
  discount: Yup.number()
    .test('couponOffType', 'Discount is required', (value, context) => {
      const { couponOffType } = context.parent;
      if (couponOffType === 'PERCENTAGE') {
        return typeof value === 'number';
      }
      return true;
    })
    .min(1, 'Discount must be grater then 0')
    .max(100, 'Discount can not be grater than 100'),
  amount: Yup.number()
    .nullable()
    .test('couponOffType', 'Amount is required', (value, context) => {
      const { couponOffType } = context.parent;
      if (couponOffType === 'FLAT') {
        return typeof value === 'number';
      }
      return true;
    })
    .min(1, 'Amount must be grater then 0'),
  startDate: Yup.string().required(getRequiredText('Start date')),
  startTime: Yup.string().required(getRequiredText('Start time')),
  endDate: Yup.string()
    .required(getRequiredText('End date'))
    .test('is-greater', 'End date must be after start date', function isGreater(endDate) {
      const { startDate } = this.parent;
      return new Date(endDate) >= new Date(startDate);
    }),
  endTime: Yup.string()
    .required(getRequiredText('End time'))
    .test('is-greater', 'End time must be after start time', function isGreater(endTime) {
      const { startDate, endDate, startTime } = this.parent;
      const startDateTime = new Date(`${startDate} ${startTime}`);
      const endDateTime = new Date(`${endDate} ${endTime}`);
      return endDateTime > startDateTime;
    }),
  applicableTo: Yup.string(),
  planIds: Yup.array().test('applicableTo', 'Plan or user is required', (value, context) => {
    const { applicableTo, userIds } = context.parent;
    if (userIds && value && userIds.length === 0 && value.length === 0) {
      return false;
    }
    if (applicableTo === 'PLAN') {
      return value && value?.length > 0;
    }
    return true;
  }),
  userIds: Yup.array().test('applicableTo', 'Pla or user is required', (value, context) => {
    const { applicableTo, planIds } = context.parent;
    if (planIds && value && planIds.length === 0 && value.length === 0) {
      return false;
    }
    if (applicableTo === 'USER') {
      return value && value?.length > 0;
    }
    return true;
  }),
});

export const addAudioMarkSchema = Yup.object().shape({
  audioName: Yup.string()
    .trim()
    .required(getRequiredText('Audio mark name'))
    .min(4, 'Minimum 4 characters required')
    .max(50, 'Maximum 50 characters allowed'),
});

export const campaignDetailsSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(getRequiredText('Campaign Name'))
    .min(4, 'Please enter a title between 4 to 50 characters')
    .max(50, 'Please enter a title between 4 to 50 characters'),
  businessName: Yup.string()
    .trim()
    .required(getRequiredText('Business Name'))
    .min(4, 'Please enter a title between 4 to 50 characters')
    .max(50, 'Please enter a title between 4 to 50 characters'),
  businessType: Yup.string()
    .required(getRequiredText('Business Type'))
    .notOneOf(['Select Business Type'], 'Please select business type'),
  businessIndustry: Yup.string()
    .required(getRequiredText('Business Industry'))
    .notOneOf(['Select Business Industry'], 'Please select business industry'),
  country: Yup.string()
    .required(getRequiredText('Country'))
    .notOneOf(['Select Country'], 'Please select your country'),
});

export const adsDetailsSchema = Yup.object().shape({
  adName: Yup.string()
    .trim()
    .required(getRequiredText('Ad Name'))
    .min(4, 'Please enter a title between 4 to 255 characters')
    .max(255, 'Please enter a title between 4 to 255 characters'),
  adCategory: Yup.string()
    .trim()
    .required(getRequiredText('Select Category'))
    .notOneOf(['Select Category'], 'Please select a valid ad category'),
  audienceCountry: Yup.array().min(1, getRequiredText('Location')),
  audienceAgeGroup: Yup.string().required('Please select a age group'),
  audienceGender: Yup.string().required('Please select a gender'),
  audienceLanguage: Yup.array().min(1, getRequiredText('Language')),
  categoryUuid: Yup.array().min(1, getRequiredText('Podcast category')),
  podcastTypeUuid: Yup.array().min(1, getRequiredText('Podcast type')),
  impressionUuid: Yup.string().required('Please select a impressions'),
  startDate: Yup.string().required('Please select a start date'),
  startTime: Yup.string().required('Please select a start time'),
  endDate: Yup.string().required('Please select a end date'),
  endTime: Yup.string().required('Please select a end time'),
});

export const adsPlacementSchema = Yup.object().shape({
  imageAds: Yup.array().of(
    Yup.object().shape({
      page: Yup.string()
        .trim()
        .required(getRequiredText('Page'))
        .notOneOf(['Select Page'], 'Please select a page'),
      imagePlacementUuid: Yup.string()
        .required(getRequiredText('Placement'))
        .notOneOf(['Select Placements of Ad'], 'Please select a placement of ad'),
      imageLanguage: Yup.string()
        .required(getRequiredText('Language'))
        .notOneOf(['Select Language'], 'Please select a valid language'),
      imageAdUrl: Yup.string().trim().required(getRequiredText('Image')),
    })
  ),
  audioAds: Yup.array().of(
    Yup.object().shape({
      audioPlacementUuid: Yup.string()
        .required(getRequiredText('Placement'))
        .notOneOf(['Select Placements of Ad'], 'Please select a placement of ad'),
      audioLanguage: Yup.string()
        .required(getRequiredText('Language'))
        .notOneOf(['Select Language'], 'Please select a valid language'),
      audioAdUrl: Yup.string().trim().required('Audio is required'),
    })
  ),
});
