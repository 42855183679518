import DISABLE_HEADER_ACTION from '../constants/disableAction';
import { IDisableActionState } from '../types/disableAction';

const initialState: IDisableActionState = {
  disableHeaderAction: false,
};

// eslint-disable-next-line
const disableAction = (state = initialState, action: any) => {
  if (action.type === DISABLE_HEADER_ACTION) {
    return { ...state, disableHeaderAction: action.payload.value };
  }
  return state;
};

export default disableAction;
