import { useEffect, useState } from 'react';

import SvgIcons from '../../assets/svg/SvgIcons';
import { CLOUDINARY_URL, DOMAIN_URL } from '../../clientConfig';
import { PLAN_ACTIONS, TOASTER_STATUS } from '../../constant';
import APP_ROUTES, { DASHBOARD_APP_ROUTES } from '../../constant/appRoute';
import { OPEN_EDITOR } from '../../constant/modal';
import { exportAudioWithAudioMark } from '../../services/podcaster/Episode';
import { paymentPlanAction } from '../../services/podcaster/Subscription';
import { AudioEnhanceInterface } from '../../types/audioContextInterface';
import { downloadAudioFile, getLocalStorage, setLocalStorage, showToastMessage } from '../../utils';
import ButtonWrapper from '../form/ButtonWrapper';
import ModalWrapper from '../form/ModalWrapper';
import Loader from '../Loader/Loader';
import AudioWave from './AudioWave';

// export interface ISubscriptionSecret {
//   plan: any;
//   stripeSecret: IStripeSecret;
// }

const AcceptedRecord = ({
  setUploadedFile,
  // setStage,
  enhancedAudio,
  setEnhancedAudio,
  // downloadPath,
  formInstance,
  handleClick,
  user,
  planUpdated,
}: AudioEnhanceInterface) => {
  const [upgradePlanPopup, setUpgradePlanPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [isRedirectOnSubscription, setIsRedirectOnSubscription] = useState<boolean>(false);
  const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [audioLoading, setAudioLoading] = useState<boolean>(true);

  const handleLoading = (value: boolean) => setIsLoading(value);
  const onCancelOpenEditor = () => setIsOpenEditor(false);
  const onOpenEditor = () => {
    setIsOpenEditor(true);
  };

  const shouldShowUpgradePlanPopup = (downloadWithAudioMark: boolean) =>
    !planUpdated &&
    !downloadWithAudioMark &&
    !user?.activePlanUuidAndEndDate?.activePlanAmount &&
    !getLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE);

  const handleDownloadWithAudioMark = async () => {
    const objectKey = formInstance?.values?.enhancedAudio?.split('podcast-episodes/')[1];
    const checkPlan =
      !user?.activePlanUuidAndEndDate?.activePlanAmount &&
      getLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE);
    setIsExporting(true);

    const responseData = await exportAudioWithAudioMark(objectKey, !checkPlan);
    if (responseData?.success) {
      if (responseData?.result?.audioMarkStatus === 'SUCCESS') {
        const downloadUrl = `${CLOUDINARY_URL}${responseData?.result?.fileUrl}`;
        const fileFormat = downloadUrl.split('.').pop() ?? '';
        downloadAudioFile(fileFormat, downloadUrl, new Date().toISOString());
      }
    } else {
      showToastMessage(TOASTER_STATUS.ERROR, responseData?.error?.txt || 'Something Went Wrong!');
    }
  };

  const handleRegularDownload = async () => {
    setIsExporting(true);
    const downloadUrl = formInstance?.values?.enhancedAudio;
    const fileFormat = downloadUrl.split('.').pop() || '';
    await downloadAudioFile(fileFormat, downloadUrl, new Date().toISOString());
  };

  const handleDownloadAudio = async (downloadWithAudioMark = false) => {
    if (isExporting) return;

    try {
      if (shouldShowUpgradePlanPopup(downloadWithAudioMark)) {
        setUpgradePlanPopup(true);
      } else if (downloadWithAudioMark) {
        await handleDownloadWithAudioMark();
      } else {
        await handleRegularDownload();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.result?.errorMessage || 'Something Went Wrong!';
      showToastMessage(TOASTER_STATUS.ERROR, errorMessage);
    } finally {
      setIsExporting(false);
    }
  };

  const onOpenEditorOk = () => {
    if (formInstance) {
      if (Object.keys(formInstance.errors).length === 0 && handleClick) {
        handleClick(
          { ...formInstance.values, status: 'draft' },
          { isOpenEditor: true, isRedirectOnSubscription: false }
        );
      } else {
        formInstance.handleSubmit();
        showToastMessage(TOASTER_STATUS.ERROR, 'Please fill all required fields');
      }
      setIsOpenEditor(false);
    }
  };

  const onUpgradePlan = () => {
    setIsRedirectOnSubscription(true);
  };

  const onCancelUpgradePlan = () => {
    setIsRedirectOnSubscription(false);
  };

  const onDownload = () => {
    if (formInstance) {
      if (Object.keys(formInstance.errors).length === 0 && handleClick) {
        handleClick(
          { ...formInstance.values, status: 'draft' },
          { isOpenEditor: false, isRedirectOnSubscription: true }
        );
      } else {
        formInstance.handleSubmit();
        setIsRedirectOnSubscription(false);
        setUpgradePlanPopup(false);
        showToastMessage(TOASTER_STATUS.ERROR, 'Please fill all required fields');
      }
      setIsRedirectOnSubscription(false);
      setUpgradePlanPopup(false);
    }
  };

  const handlePaymentSuccess = (event: MessageEvent<any>, paymentWindow: any) => {
    if (event.data === 'paymentSuccess' && event.source === paymentWindow) {
      paymentWindow?.close();
      setLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE, true);
      setUpgradePlanPopup(false);
      handleDownloadAudio();
      window.removeEventListener('message', (e) => handlePaymentSuccess(e, paymentWindow));
    }
  };

  useEffect(() => {
    if (paymentIntent) {
      const paymentWindow: any = window.open(
        `${DOMAIN_URL}${DASHBOARD_APP_ROUTES.DASHBOARD}/${APP_ROUTES.PAYMENT}`,
        '_blank',
        'width=768,height=600'
      );

      if (paymentWindow) {
        paymentWindow.paymentIntent = paymentIntent;
        window.addEventListener('message', (event) => handlePaymentSuccess(event, paymentWindow));
      }
    }
  }, [paymentIntent]);

  const handlePaymentIntent = (value: any) => {
    setPaymentIntent(value);
  };

  const handlePaymentPlanAction = (planAction: any) => {
    paymentPlanAction(planAction, 0, 0, handleLoading, handlePaymentIntent);
  };

  // eslint-disable
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          {/* eslint-disable-next-line */}
          <label htmlFor='podcast-title'>Audio</label>
          <div className='position-relative big-height record-audio mb-40'>
            <div className='download-last-record'>
              <button
                type='button'
                className='edit-btn'
                onClick={onOpenEditor}
                // onKeyDown={(event) => {
                //   if (event.key === 'Enter' || event.key === ' ') {
                //     onOpenEditor();
                //   }
                // }}
              >
                <div className='open-editor'>Open Editor</div>
              </button>
            </div>
            <AudioWave
              classString='enhance-wave'
              link={
                // eslint-disable-next-line
                enhancedAudio
                  ? enhancedAudio instanceof File
                    ? URL?.createObjectURL(enhancedAudio)
                    : enhancedAudio
                  : ''
              }
              audioLoading={audioLoading}
              setAudioLoading={setAudioLoading}
            />
          </div>
        </div>
        <div>
          <div className='d-flex gap-5 justify-content-center'>
            <button
              type='button'
              className='btn-primary btn-default tertiary-btn'
              onClick={() => setDeleteModal(true)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  setDeleteModal(true);
                }
              }}
            >
              Cancel
            </button>
            <button
              type='button'
              disabled={isExporting}
              className='btn-primary btn-default'
              onClick={() => handleDownloadAudio()}
            >
              {isExporting && !upgradePlanPopup ? (
                <div className='d-flex gap-2'>
                  <Loader className='theme-loader' />
                  Downloading
                </div>
              ) : (
                'Download Audio'
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpenEditor && (
        <ModalWrapper
          size='lg'
          show={isOpenEditor}
          body={{
            title: OPEN_EDITOR.BODY.title,
            content: OPEN_EDITOR.BODY.content,
            imgPath: 'dashboard/Alert/Info.svg',
          }}
          button1={{
            children: OPEN_EDITOR.BUTTON1_CHILDREN,
            onClick: onCancelOpenEditor,
            className: 'close-btn',
          }}
          button2={{
            children: OPEN_EDITOR.BUTTON2_CHILDREN,
            onClick: onOpenEditorOk,
            className: 'save-btn',
          }}
          isButton
          modalType='submit'
          handleClose={onCancelOpenEditor}
          className='open-editor-modal'
        />
      )}

      {deleteModal && (
        <ModalWrapper
          size='lg'
          modalType='delete'
          show={deleteModal}
          body={{
            title: 'Do you really want to remove this audio?',
            imgPath: 'dashboard/Alert/Error.svg',
          }}
          button1={{
            children: OPEN_EDITOR.BUTTON1_CHILDREN,
            onClick: () => setDeleteModal(false),
            className: 'close-btn',
          }}
          button2={{
            children: OPEN_EDITOR.BUTTON2_CHILDREN,
            onClick: () => {
              if (getLocalStorage(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE))
                localStorage.removeItem(PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE);
              setEnhancedAudio('');
              setUploadedFile('');
              // setStage(0);
            },
            className: 'save-btn',
          }}
          isButton
          handleClose={() => setDeleteModal(false)}
          className='open-editor-modal'
        />
      )}

      {isRedirectOnSubscription && (
        <ModalWrapper
          size='sm'
          show={isRedirectOnSubscription}
          body={{
            title: '',
            content:
              'Your work will be saved as a draft, and you will be redirected to the subscription page.',
          }}
          button1={{
            children: OPEN_EDITOR.BUTTON1_CHILDREN,
            onClick: onCancelUpgradePlan,
          }}
          button2={{
            children: OPEN_EDITOR.BUTTON2_CHILDREN,
            onClick: onDownload,
          }}
          handleClose={onCancelUpgradePlan}
          className='open-editor-modal'
        />
      )}

      {upgradePlanPopup && (
        <ModalWrapper
          size='lg'
          show={upgradePlanPopup}
          handleClose={() => {
            if (!isExporting) setUpgradePlanPopup(false);
          }}
          body={{
            title: 'Download Podcast',
            content:
              'You need to upgrade your plan to enable downloading podcasts without audio marks.',
            icon: { Element: SvgIcons, type: 'upgrade-plan' },
          }}
          modalType='submit'
          customElement={
            <div className='audio-mark-btn'>
              <ButtonWrapper
                isDisabled={isExporting}
                onClick={() => {
                  handleDownloadAudio(true);
                }}
              >
                {isExporting ? (
                  <div className='d-flex gap-2'>
                    <Loader />
                    Downloading
                  </div>
                ) : (
                  'Download With Audio Mark'
                )}
              </ButtonWrapper>
            </div>
          }
          button1={{
            children: isLoading ? <Loader /> : 'Pay $1 For Download',
            isDisabled: isExporting || isLoading,
            onClick: () => {
              handleLoading(true);
              handlePaymentPlanAction({
                planName: 'Download Podcast',
                amount: 1,
                planFeatures: [],
                period: '',
                actionName: [PLAN_ACTIONS.DOWNLOAD_RECORDED_FILE],
              });
            },
          }}
          button2={{
            children: 'Upgrade Plan',
            isDisabled: isExporting,
            onClick: onUpgradePlan,
          }}
          bodyClass='upgrade-plan-modal-body download-popup'
        />
      )}
    </div>
  );
};

AcceptedRecord.defaultProps = {
  AcceptedRecord: undefined,
};

export default AcceptedRecord;
