import { LeftPanelDashboardTypes } from './LeftPanelDashboard.Types';

const LeftPanelDashboardData: LeftPanelDashboardTypes[] = [
  {
    id: 'Podcast Management Section',
    text: 'Podcast Management', // Placeholder, will be replaced with translations
    menu: [
      {
        id: 'add_new',
        icon: 'AddNew',
        label: 'New Podcast', // Placeholder for translation
        link: '/dashboard/podcast-add',
      },
      {
        id: 'my_podcast',
        icon: 'MyPodcast',
        label: 'My Podcast', // Placeholder for translation
        link: '/dashboard/my-podcast',
      },
      {
        id: 'draft',
        icon: 'Draft',
        label: 'Draft', // Placeholder for translation
        link: '/dashboard/draft',
      },
      {
        id: 'analytics',
        icon: 'Analytics',
        label: 'Analytics', // Placeholder for translation
        link: '/dashboard/analytics',
      },
    ],
  },
  {
    id: 'Library Section',
    text: 'Library', // Placeholder for translation
    menu: [
      {
        id: 'discover',
        icon: 'Discover',
        label: 'Discover', // Placeholder for translation
        link: '/dashboard/discover',
      },
      {
        id: 'favorite',
        icon: 'Favorite',
        label: 'Favorite', // Placeholder for translation
        link: '/dashboard/favorites',
      },
      {
        id: 'downloads',
        icon: 'Downloads',
        label: 'Downloads', // Placeholder for translation
        link: '/dashboard/downloads',
      },
      {
        id: 'subscriptions',
        icon: 'Notification',
        label: 'Subscriptions', // Placeholder for translation
        link: '/dashboard/subscriptions/podcasts',
      },
      {
        id: 'recent',
        icon: 'Recent',
        label: 'Recent', // Placeholder for translation
        link: '/dashboard/recent',
      },
    ],
  },
  {
    id: 'General Section',
    text: 'General', // Placeholder for translation
    menu: [
      {
        id: 'profile',
        icon: 'Profile',
        label: 'Profile', // Placeholder for translation
        link: '/dashboard/profile',
      },
      {
        id: 'password',
        icon: 'Password',
        label: 'Password', // Placeholder for translation
        link: '/dashboard/password/update',
      },
    ],
  },
  {
    id: 'Others Section',
    text: 'Others', // Placeholder for translation
    menu: [
      {
        id: 'dark_mode',
        icon: 'DarkMode',
        label: 'Dark mode', // Placeholder for translation
        isButton: true,
      },
      {
        id: 'logout',
        icon: 'Logout',
        label: 'Log out', // Placeholder for translation
        isButton: true,
      },
    ],
  },
];

export default LeftPanelDashboardData;
