import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DarkAvatar from '../../../../../../assets/svg/dashboard/dark/avatar.svg';
import IconComponent from '../../../../../../components/Icon/Icon.Component';
import { useAppContext } from '../../../../../../context/App/AppContext';
import { useLanguage } from '../../../../../../context/LanguageContext';
import { getUserDetailAction } from '../../../../../../redux/actions/user';
import { showAudioPlayer } from '../../../../../../redux/selectors/audioPlayer';
import { getEditorEpisodeCount } from '../../../../../../redux/selectors/podcaster/audioEditor';
import { IState } from '../../../../../../redux/types';
import { IUser } from '../../../../../../types';
import { clearLocalStorage } from '../../../../../../utils';

const UserMenuComponent: React.FC<{ user: IUser }> = ({ user }) => {
  const navigate = useNavigate();
  const { isLightTheme } = useAppContext();
  const { language } = useLanguage();
  const [content, setContent] = useState<any>({
    userMenu: {
      viewProfile: '',
      changePassword: '',
      settings: '',
      logout: '',
    },
  });

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../../../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  return (
    <div className='inner'>
      <div className='rbt-admin-profile'>
        <div className='admin-thumbnail'>
          {user?.profilePhotoUrl! ? (
            <img
              src={user?.profilePhotoUrl}
              alt={user.firstName}
              className='img-fluid rounded me-3 flex-none'
            />
          ) : (
            <img src={DarkAvatar} alt='avatar' />
          )}
        </div>
        <div className='admin-info'>
          <span className='name'>{`${user.firstName}  ${user.lastName}`}</span>
          <span className='mt--3'>{user.email}</span>
        </div>
      </div>
      <hr className='mt--10 mb--10' />
      <ul className='user-list-wrapper user-nav'>
        <li>
          <Link to='/dashboard/profile'>
            <IconComponent
              name='Profile'
              path={isLightTheme ? `dashboard/light/Profile.svg` : `dashboard/light/Profile.svg`}
            />
            <span>{content.userMenu.viewProfile}</span>
          </Link>
        </li>
        <li>
          <Link to='/dashboard/password/update'>
            <IconComponent
              name='Password'
              path={isLightTheme ? `dashboard/light/Password.svg` : `dashboard/light/Password.svg`}
            />
            <span>{content.userMenu.changePassword}</span>
          </Link>
        </li>
        <li>
          <Link to='/dashboard/settings'>
            <IconComponent
              name='Settings'
              path={isLightTheme ? `dashboard/light/Settings.svg` : `dashboard/light/Settings.svg`}
            />
            <span>{content.userMenu.settings}</span>
          </Link>
        </li>
      </ul>
      <hr className='mt--10 mb--10' />
      <ul className='user-list-wrapper'>
        <li>
          <button
            type='button'
            onClick={async () => {
              await clearLocalStorage();
              navigate('/login');
            }}
          >
            <IconComponent
              name='Logout'
              path={isLightTheme ? `dashboard/light/Logout.svg` : `dashboard/light/Logout.svg`}
            />
            <span>{content.userMenu.logout}</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  user: state.user,
  hasAudioPlayer: showAudioPlayer(state),
  editorEpisodeCount: getEditorEpisodeCount(state),
});

const mapDispatchToProps = {
  getUserDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuComponent);
