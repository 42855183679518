import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoWhite from '../../../../assets/images/dark/dashboard/LogoWhite.png';
import Logo from '../../../../assets/images/light/dashboard/Logo.png';
import IconComponent from '../../../../components/Icon/Icon.Component';
import { TOASTER_STATUS, USER_ROLE } from '../../../../constant';
import { DASHBOARD_APP_ROUTES } from '../../../../constant/appRoute';
import { useAppContext } from '../../../../context/App/AppContext';
import { useLanguage } from '../../../../context/LanguageContext';
import { getUserDetailAction, updateUserDetailAction } from '../../../../redux/actions/user';
import { getUser } from '../../../../redux/selectors/user';
import { IState } from '../../../../redux/types';
import { clearLocalStorage, showToastMessage } from '../../../../utils';
import LeftPanelDashboardData from './Data/LeftPanelDashboard.Data';
import { LeftPanelDashboardTypes } from './Data/LeftPanelDashboard.Types';

const LeftPanelDashboard: React.FC<{ role?: string }> = ({ role }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { shouldCollapseLeftbar, isLightTheme, toggleTheme } = useAppContext();
  const { language } = useLanguage(); // Access the language context
  const [translations, setTranslations] = useState<any>({});

  const handleLogout = () => {
    if (role === USER_ROLE.ADMIN) {
      navigate('/admin/login');
    } else {
      navigate('/login');
    }
  };

  const onClickHandler = (type: string): void => {
    if (type === 'logout') {
      clearLocalStorage();
      showToastMessage(TOASTER_STATUS.SUCCESS, 'Logout Successfully.');
      handleLogout();
    } else if (type === 'dark_mode') {
      toggleTheme();
    }
  };

  const isActive = useCallback(
    (path: string) =>
      location.pathname === path || location.pathname.includes(path) ? 'active' : '',
    [location.pathname]
  );

  useEffect(() => {
    const loadTranslations = async () => {
      const content = await import(`../../../../i18n/locales/${language}.json`);
      setTranslations(content);
    };

    loadTranslations();
  }, [language]);

  // Update LeftPanelDashboardData with translations
  const translatedData = LeftPanelDashboardData.map((section) => ({
    ...section,
    text: translations[section.id]?.text || section.text,
    menu: section.menu.map((menuItem) => ({
      ...menuItem,
      label: translations[section.id]?.menu?.[menuItem.id]?.label || menuItem.label, // Access nested labels
    })),
  }));

  return (
    <div
      className={`rbt-sidebar ${
        shouldCollapseLeftbar ? 'collapsed' : ''
      } ${language === 'ar' ? 'rtl rtl-dashboard' : ''}`}
    >
      <div className='rbt-default-sidebar'>
        <div className='inner'>
          <div className='header-left d-flex logo-section'>
            <div className='logo'>
              <Link to={`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.DISCOVER}`}>
                {isLightTheme ? (
                  <img src={LogoWhite} alt='avatar' className='mr--5' />
                ) : (
                  <img src={Logo} alt='avatar' className='mr--5' />
                )}
              </Link>
            </div>
          </div>
          <div className='content-item-content'>
            <div className='rbt-default-sidebar-wrapper'>
              <nav className='mainmenu-nav'>
                {translatedData.map((item: LeftPanelDashboardTypes) => (
                  <div key={item.id}>
                    <div className='title mt--20'>{item.text}</div>
                    <ul className='dashboard-mainmenu rbt-default-sidebar-list'>
                      {item.menu.map((menuItem) => {
                        const iconPath = isLightTheme
                          ? `dashboard/dark/${menuItem.icon}.svg`
                          : `dashboard/light/${menuItem.icon}.svg`;
                        if (menuItem.id === 'logout' && menuItem.isButton)
                          return (
                            <li key={menuItem.id}>
                              <button
                                type='button'
                                className='menu-item'
                                onClick={() => onClickHandler(menuItem.id)}
                              >
                                <IconComponent path={iconPath} name={menuItem.label} />
                                <span>{menuItem.label}</span>
                              </button>
                            </li>
                          );

                        if (menuItem.id === 'dark_mode' && menuItem.isButton)
                          return (
                            <li key={menuItem.id}>
                              <div className='menu-item'>
                                <label className='d-flex align-items-center' htmlFor='switch-mood'>
                                  <span className='switch'>
                                    <input
                                      type='checkbox'
                                      id='switch-mood'
                                      onClick={() => toggleTheme()}
                                    />
                                    <span className='slider' />
                                  </span>
                                  <span>
                                    {isLightTheme
                                      ? translations['Others Section']?.menu?.light_mode?.label ||
                                        'Light mode'
                                      : translations['Others Section']?.menu?.dark_mode?.label ||
                                        'Dark mode'}
                                  </span>
                                </label>
                              </div>
                            </li>
                          );
                        return (
                          <li key={menuItem.id}>
                            {!menuItem.isButton && (
                              <Link className={`${isActive(menuItem.link!)}`} to={menuItem.link!}>
                                <IconComponent path={iconPath} name={menuItem.label} />
                                <span>{menuItem.label}</span>
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
                <div className='rbt-sm-separator' />
              </nav>
            </div>
          </div>
        </div>
        <div className='subscription-box'>
          <div className='inner'>
            <div className='btn-part'>
              <Link
                to={`/${DASHBOARD_APP_ROUTES.DASHBOARD}/${DASHBOARD_APP_ROUTES.SUBSCRIPTION}`}
                className='btn-default'
              >
                {translations.upgrade_to_pro?.label || 'Upgrade To Pro'}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  updateUserDetailAction,
  getUserDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanelDashboard);
