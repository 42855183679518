import { type FC } from 'react';

import { useFormikContext } from 'formik';
import EmptyImage from '../../../../../../../../../assets/svg/dashboard/EmptyImage.svg';
import { IPodcastForm } from '../../../../../../../../../types/podcastInterface';
import { ReviewType } from './Review.Types';

const ReviewStep: FC<ReviewType> = () => {
  const { values } = useFormikContext<IPodcastForm>();

  const imageSrc = (img: any, type?: string) => {
    if (typeof img === 'string') {
      return img;
    }
    if (img) {
      return URL.createObjectURL(img as Blob);
    }
    return type === 'thumbnail' ? EmptyImage : '';
  };

  return (
    <div className='d-flex flex-column review-wrapper'>
      <div className='images'>
        <img
          src={values.thumbnail ? imageSrc(values.thumbnail, 'thumbnail') : EmptyImage}
          alt={values.podcastTitle}
          className='image-view thumbnail-bg'
        />
        <div
          style={{
            backgroundImage: `url(${imageSrc(values.cover)})`,
          }}
          className='image-view background-bg background-review'
        />
      </div>
      <span>title</span>
      <h3>{values.podcastTitle}</h3>
      <span>Description</span>
      <p className='description mb-5'>{values.description}</p>

      <div className='row mb-5'>
        <div className='col-12 col-lg-6'>
          <span>Category</span>
          <p className='country'>{values.podcastCategory}</p>
        </div>
        <div className='col-12 col-lg-6'>
          <span>Type</span>
          <p className='country'>{values.podcastType}</p>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-12 col-lg-6'>
          <span>Language</span>
          <p className='country'>{values.language}</p>
        </div>
        <div className='col-12 col-lg-6'>
          <span>Country of Origin</span>
          <p className='country'>{values.country}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;
