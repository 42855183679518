import { ReactNode } from 'react';

export type ManagementPodcastStepsTypeEnumKey =
  | 'upload_audio'
  | 'visual_media'
  | 'podcast_details'
  | 'appearance_settings'
  | 'review'
  | 'separator';

export type TabItemData = {
  key: ManagementPodcastStepsTypeEnumKey;
  disabled?: boolean;
  label?: ReactNode;
  inlineTrailingIcon?: string;
};

export const ManagementPodcastStepsTabs: TabItemData[] = [
  {
    key: 'podcast_details',
  },
  {
    key: 'separator',
    inlineTrailingIcon: 'el-arrow-right-ultra',
  },
  {
    key: 'visual_media',
  },
  {
    key: 'separator',
    inlineTrailingIcon: 'el-arrow-right-ultra',
  },
  {
    key: 'upload_audio',
  },
  {
    key: 'separator',
    inlineTrailingIcon: 'el-arrow-right-ultra',
  },
  {
    key: 'appearance_settings',
  },
  {
    key: 'separator',
    inlineTrailingIcon: 'el-arrow-right-ultra',
  },
  {
    key: 'review',
  },
];
