import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { DASHBOARD_APP_ROUTES } from '../../constant/appRoute';

/**
  A route that should only be accessed when user IS NOT logged in.
*/
const PublicRoute: FC<{ children: JSX.Element }> = ({ children }) => {
  const isAuthenticated = localStorage.getItem('accessToken');

  if (!isAuthenticated) {
    return children;
  }

  return <Navigate to={`/${DASHBOARD_APP_ROUTES.DASHBOARD}`} />;
};

export default PublicRoute;
