import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoDark from '../../../../assets/images/logo-dark.png';
import LogoWhite from '../../../../assets/images/logo.png';
import LogoDarkArabic from '../../../../assets/images/musajjel-arabic-logos/logo-dark.png';
import LogoWhiteArabic from '../../../../assets/images/musajjel-arabic-logos/logo-white.png';
import DarkAvatar from '../../../../assets/svg/dashboard/dark/avatar.svg';
import LightAvatar from '../../../../assets/svg/dashboard/light/avatar.svg';
import Plus from '../../../../assets/svg/dashboard/plus.svg';
import IconComponent from '../../../../components/Icon/Icon.Component';
import { useAppContext } from '../../../../context/App/AppContext';
import { useLanguage } from '../../../../context/LanguageContext';
import { IState } from '../../../../redux/types';
import { resendEmail } from '../../../../services/listener/Auth';
import { IUser } from '../../../../types';
import { getLocalStorage } from '../../../../utils';
import UserMenuComponent from './Components/UserMenu/UserMenu.Component';

interface IContent {
  header: {
    dashboard: string;
    new_podcast: string;
    verify_banner: string;
    verify_now: string;
    switch_to_arabic: string;
    switch_to_english: string;
  };
}

const HeaderDashboard: React.FC<{ user: IUser }> = ({ user }) => {
  const { isLightTheme, setMobile, mobile } = useAppContext();
  const isUserVerifyEmail = getLocalStorage('isUserVerifyEmail');
  const { language, setLanguage } = useLanguage(); // Access the language context

  const [content, setContent] = useState<IContent>({
    header: {
      dashboard: '',
      new_podcast: '',
      verify_banner: '',
      verify_now: '',
      switch_to_arabic: '',
      switch_to_english: '',
    },
  });

  console.log('Current language:', language);

  useEffect(() => {
    const loadContent = async () => {
      const langContent = await import(`../../../../i18n/locales/${language}.json`);
      setContent(langContent);
    };

    loadContent();
  }, [language]);

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'ar' : 'en');
  };

  const getLogo = () => {
    if (language === 'ar') return isLightTheme ? LogoWhiteArabic : LogoDarkArabic;

    return isLightTheme ? LogoWhite : LogoDark;
  };

  return (
    <header
      style={{ height: `${isUserVerifyEmail?.success === false ? '' : 'auto'}` }}
      className='rbt-dashboard-header rainbow-header header-default header-left-align rbt-fluid-header'
    >
      {isUserVerifyEmail?.success === false && (
        <div className={`verify-banner mb-3 ${isLightTheme ? '' : 'is-light-banner'}`}>
          <IconComponent name='Upload' path='dashboard/Alert/Info.svg' />
          <p className='banner-verify-text'>{content.header.verify_banner}</p>
          <button
            type='button'
            onClick={async () => {
              await resendEmail(encodeURIComponent(user.email));
            }}
          >
            {content.header.verify_now}
          </button>
        </div>
      )}
      <div
        className={`position-relative ${isUserVerifyEmail?.success === false ? 'py-2' : 'py-3'}`}
      >
        <div className='row align-items-center'>
          <div className='col-lg-2 col-md-6 col-7'>
            <div className='d-block d-xl-none mobile-logo'>
              <img src={getLogo()} alt='Logo' />
            </div>
          </div>

          <div className='col-lg-10 col-md-6 col-5'>
            <div className='header-right'>
              <button type='button' onClick={toggleLanguage} className='btn btn-primary'>
                {language === 'en'
                  ? `${content.header.switch_to_arabic} (التبديل إلى العربية)`
                  : `${content.header.switch_to_english} (Switch to English)`}
              </button>
              <div className='header-btn d-none d-md-block'>
                <Link
                  className='btn-default bg-gradient-secondary btn-small d-flex align-items-center'
                  to='/dashboard/podcast-add'
                >
                  <img src={Plus} alt='avatar' className='mr--5' />
                  {content.header.new_podcast}
                </Link>
              </div>

              <div className='mobile-menu-bar mr--10 ml--10 d-block d-xl-none'>
                <div className='hamberger'>
                  <button
                    type='button'
                    className='hamberger-button'
                    onClick={() => setMobile(!mobile)}
                  >
                    <IconComponent path='dashboard/Menu.svg' name='menu' />
                  </button>
                </div>
              </div>

              <div className='account-access rbt-user-wrapper right-align-dropdown'>
                <div className='rbt-user'>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {user?.profilePhotoUrl ? (
                    <img src={user?.profilePhotoUrl} alt={user.firstName} className='user-img' />
                  ) : isLightTheme ? (
                    <img src={DarkAvatar} alt='avatar' />
                  ) : (
                    <img src={LightAvatar} alt='avatar' />
                  )}
                </div>
                <div className='rbt-user-menu-list-wrapper'>
                  <UserMenuComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: IState) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(HeaderDashboard);
