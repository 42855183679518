import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import './date-picker-wrapper.scss';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import SvgIcons from '../../assets/svg/SvgIcons';

interface IProps {
  name?: string;
  className?: string;
  label?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  required?: boolean;
  placeholder?: string;
  error: string | undefined;
  value: any;
  onChange: CallableFunction;
  disabled?: boolean;
  selectPresentTime?: boolean;
  minDate?: any;
  maxDate?: any;
}

const DatePickerWrapper: FC<IProps> = ({
  name,
  className,
  label,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  timeIntervals,
  dateFormat,
  required,
  placeholder,
  error,
  value,
  onChange,
  disabled,
  selectPresentTime,
  minDate,
  maxDate,
}) => {
  const today = moment().format('YYYY-MM-DD');
  const combinedDateTimeString = `${today} ${value}`;
  const datePickerValue = showTimeSelectOnly
    ? moment(combinedDateTimeString).toDate()
    : moment(value).toDate();

  const [date, setDate] = useState(value ? datePickerValue : null);

  return (
    <div className={`date-picker-wrapper from-group-wrapper mb-0 ${className}`}>
      <label htmlFor='date-picker' className='form-label'>
        {label}
        {required && <span className='required red'>*</span>}
      </label>
      <DatePicker
        name={name}
        className='form-control big-datepicker-input mb-4'
        selected={selectPresentTime ? new Date() : date}
        onChange={(d: any) => {
          if (!showTimeSelect) {
            onChange(moment(d).format('YYYY-MM-DD'));
          } else {
            onChange(moment(d).format('h:mm A'));
          }
          setDate(d);
        }}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
      />

      <div className='calendar-icon'>
        <SvgIcons iconType={showTimeSelectOnly ? 'time-picker-icon' : 'date-picker-icon'} />
      </div>

      {error && <span className='text-danger position-absolute fs-6 fw-normal error'>{error}</span>}
    </div>
  );
};

DatePickerWrapper.defaultProps = {
  name: '',
  className: '',
  showTimeSelect: false,
  showTimeSelectOnly: false,
  timeIntervals: 15,
  timeCaption: 'Time',
  dateFormat: 'dd/MM/yyyy',
  required: false,
  placeholder: 'dd/mm/yyyy',
  disabled: false,
  minDate: null,
  maxDate: null,
};

export default DatePickerWrapper;
