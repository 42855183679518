import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

type LanguageContextType = {
  language: string;
  setLanguage: (language: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Check localStorage for the previously selected language, or default to 'en'
  const storedLanguage = localStorage.getItem('language') || 'en';

  const [language, setLanguage] = useState(storedLanguage);

  useEffect(() => {
    // Save language selection to localStorage whenever it changes
    localStorage.setItem('language', language);
  }, [language]);

  const value = useMemo(() => ({ language, setLanguage }), [language, setLanguage]);

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
