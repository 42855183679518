import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  CLOUDINARY_URL,
  FACEBOOK_PIXEL_ID,
  GOOGLE_APP_ID,
  GOOGLE_TRACKING_ID,
} from './clientConfig';
import { LanguageProvider } from './context/LanguageContext';
import GoogleFacebookAnalytics from './GoogleFacebookAnalytics.jsx';
import Router from './routing/Router';
import { getCookie, setCookie } from './utils';

// ========= Plugins CSS START =========
// import "./assets/css/plugins/feature.css";
// import "./assets/css/plugins/fontawesome-all.min.css";
// import "./assets/css/plugins/animation.css";
// import "../node_modules/sal.js/dist/sal.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "react-tooltip/dist/react-tooltip.css";
// ========= Plugins CSS END =========
import './assets/scss/style.scss';
import AppContext from './context/App/AppContext';

const App = () => {
  const imageBaseUrl = CLOUDINARY_URL;

  const imageProperties = {
    '--background-login-Image': 'RightSideLogin.svg',
    '--background-set-password': 'RightSidePassword.svg',
    '--background-Image': 'background.png',
    '--dorp-right-arrow': 'dorp-right-arrow.svg',
    '--search-icon': 'search-icon.svg',
    '--home-bottom': 'home-bottom.png',
    '--clone-icon': 'clone-icon.svg',
  };

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const { userAgent } = navigator;
    const isWebView =
      /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
      /Android.*wv/.test(userAgent);

    if (isWebView) {
      setShowModal(true);
    }
    const root = document.documentElement;

    Object.entries(imageProperties).forEach(([property, image]) => {
      root.style.setProperty(property, `url(${imageBaseUrl}${image})`);
    });

    // code to generate a random id for episode view count analytics api for non-logged in users
    const userCookieId = getCookie('userCookieId');
    if (!userCookieId) {
      const newUserId = Math.floor(100000 + Math.random() * 900000);
      setCookie('userCookieId', newUserId, 365 * 24 * 60 * 60 * 1000);
    }
  }, []);

  const openInNativeBrowser = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).catch((error) => {
      console.error('Failed to copy URL to clipboard:', error);
    });
  };

  return (
    <LanguageProvider>
      <DndProvider backend={HTML5Backend}>
        <div>
          {process.env.REACT_APP_ENV === 'PROD' && (
            <GoogleFacebookAnalytics trackingId={GOOGLE_TRACKING_ID} pixelId={FACEBOOK_PIXEL_ID} />
          )}
          <Modal
            show={showModal}
            onRequestClose={() => {}}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='podcast-successfully enable-microphone-popup'
          >
            <Modal.Body className='modal-body podcast-successfully py-5 d-flex flex-column w-100 align-items-center gap-2'>
              <p className='mw-100 text-wrap py-2 text-sm bowser-comptability-text '>
                Hey there! For the smoothest experience, we recommend opening this link in your
                device&apos;s native browser. Just copy the link below and paste it into your
                browser. Enjoy!
              </p>
              <Button
                className='btn-primary btn-style center'
                onClick={openInNativeBrowser}
                type='button'
              >
                Copy Link
              </Button>
            </Modal.Body>
          </Modal>
          <GoogleOAuthProvider clientId={GOOGLE_APP_ID || ''}>
            <AppContext>
              <Router />
            </AppContext>
          </GoogleOAuthProvider>
        </div>
      </DndProvider>
    </LanguageProvider>
  );
};

export default App;
