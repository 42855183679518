import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { OPEN_APP_ROUTES } from '../../constant/appRoute';

/**
  A route that should only be accessed when user IS logged in.
*/
const PrivateRoute: FC<{ children: JSX.Element }> = ({ children }) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const location = useLocation();

  if (!isAuthenticated) {
    // Redirect to login page and add 'return_to' param. for redirect after successful login
    return (
      <Navigate
        to={`/${OPEN_APP_ROUTES.LOGIN}?return_to=${encodeURIComponent(location.pathname)}`}
      />
    );
  }

  return children;
};

export default PrivateRoute;
