import { FC, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import IconComponent from '../../../../components/Icon/Icon.Component';
import { useLanguage } from '../../../../context/LanguageContext';
import { UserNavProps } from './UserNav.Types';

const UserNavSection: FC<UserNavProps> = ({ title, navList, onBackClick }) => {
  const { language } = useLanguage(); // Get the current language (e.g., 'en' or 'ar')
  const location = useLocation();

  const isActive = useCallback(
    (path: string) => (location.pathname === path ? 'active' : ''),
    [location.pathname]
  );

  // Determine the alignment based on language
  const alignmentClass = language === 'ar' ? 'justify-content-end' : 'justify-content-start';

  return (
    <div className='banner-area'>
      <div className='settings-area'>
        <div className={`d-flex align-items-center gap-3 ${alignmentClass}`}>
          {onBackClick && (
            <button type='button' onClick={onBackClick}>
              <IconComponent name='Back' path='dashboard/Back.svg' />
            </button>
          )}
          <h4 className='title mb-0'>{title}</h4>
        </div>
        {navList && (
          <ul className={`user-nav ${alignmentClass}`}>
            {navList.map((item) => (
              <li key={item.link}>
                <Link to={item.link} className={isActive(item.link)}>
                  <span>{item.label}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserNavSection;
